import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  Badge,
  useToast,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Button,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { getRoleColor } from "../../utils/helpers";
import RegisterAdminModal from "../../components/common/registerAdminModal";
import moment from "moment";
import { TbDotsVertical } from "react-icons/tb";
import DisableOrDeleteModal from "../drivers/disableDeleteUser";
import { jwtDecode } from "jwt-decode";

const AdminsTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  onReload,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [currentUser, setCurrentUser] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [loggedinUser, setLoggedInUser] = useState({});

  useEffect(() => {
    setCurrentPage(pageNum);
    const token = localStorage.getItem("access_token");
    if (token) {
      const decoded = jwtDecode(token);
      setLoggedInUser(decoded);
    }
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Admins
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No admins available, once they are available
                                  they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr
                        onClick={() => {
                          setCurrentUser(data);
                          setIsModalVisible(true);
                        }}
                        cursor={"pointer"}
                      >
                        <Td>
                          <Box>{data?.phoneNumber}</Box>
                          <Box>{data?.username}</Box>
                        </Td>
                        <Td>{data?.email}</Td>
                        <Td>
                          <Badge
                            textTransform={"capitalize"}
                            color={"#fff"}
                            bg={getRoleColor(data?.role)}
                          >
                            {data?.role?.toLowerCase()?.replace("_", " ")}
                          </Badge>
                        </Td>

                        <Td>
                          <Badge
                            textTransform={"capitalize"}
                            color={"#fff"}
                            bg={
                              data?.status == "ACTIVE" ? "darkgreen" : "crimson"
                            }
                          >
                            {data?.status?.toLowerCase()?.replace("_", " ")}
                          </Badge>
                        </Td>
                        <Td>
                          {moment(new Date(data?.createdAt)).format(
                            "DD/MM/YY hh:mm A"
                          )}
                        </Td>

                        <Td onClick={(e) => e.stopPropagation()}>
                          <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                isDisabled={
                                  loggedinUser?.id == data?.id ? true : false
                                }
                                onClick={() => {
                                  setCurrentUser(data);
                                  setIsModalVisible2(true);
                                }}
                              >
                                {data?.status == "ACTIVE"
                                  ? "Disable user"
                                  : "Enable user"}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(`/users?page=${page}`);
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {isModalVisible2 && (
        <DisableOrDeleteModal
          isOpen={isModalVisible2}
          type="users"
          status={currentUser?.status == "DISABLED" ? "ACTIVE" : "DISABLED"}
          onOpen={() => {
            setIsModalVisible2(true);
          }}
          onClose={() => {
            setIsModalVisible2(false);
          }}
          btnTitle={currentUser?.status == "DISABLED" ? "Enable" : "Disable"}
          description={`Are you sure you want to ${
            currentUser?.status == "DISABLED" ? "enable" : "disable"
          } this user?`}
          title={
            currentUser?.status == "DISABLED" ? "Enable User" : `Disable User`
          }
          userId={currentUser?.id}
          onSuccess={(message) => {
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: message,
              isClosable: true,
              duration: 2000,
            });
            setIsModalVisible2(false);
            onReload();
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
        />
      )}

      {isModalVisible && (
        <RegisterAdminModal
          action="edit"
          onRegister={() => {
            toast({
              position: "top",
              title: "Success!",
              description: "User updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsModalVisible(false);
            onReload();
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          isOpen={isModalVisible}
          currentUser={currentUser}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </Box>
  );
};

export default AdminsTable;
