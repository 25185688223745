import { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import {
  Text,
  Input,
  useToast,
  Box,
  Center,
  Button,
  Flex,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterAdminModal from "../../components/common/registerAdminModal";
import DriversTable from "./driversTable";
import { adminService } from "../../services/admins";
import { driversService } from "../../services/drivers";
import RegisterDriverModal from "../../components/common/registerDriverModal";
import { socketConnection } from "../../utils/socket";
import { BsBarChartLine } from "react-icons/bs";
import { HiUsers } from "react-icons/hi2";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { LuLineChart } from "react-icons/lu";
import { CiFilter } from "react-icons/ci";

const Drivers = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(-1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [totalNum, setTotalNum] = useState(0);
  const [currentOption, setCurrentOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const page = searchParams.get("page");
    const status = searchParams.get("status");

    const key = searchParams.get("searchKey");
    if (key) {
      setSearchQuery(key);
    } else {
      setSearchQuery(null);
    }

    if (parseInt(page) > 0) {
      setPageNum(page);
      if (status && status != "null") {
        setCurrentOption(status);
      } else {
        setCurrentOption(null);
      }
      return;
    }
    return navigate("/drivers?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "Username",
    },
    {
      name: "Phone Number",
    },
    {
      name: "Email",
    },
    {
      name: "Vehicle",
    },
    {
      name: "Driver Type",
    },
    {
      name: "Account Status",
    },
    {
      name: "Created At",
      sortable: true,
    },
    {
      name: "Manage",
    },
  ];

  const getDrivers = async (limit, pageNum, currentOption, searchQuery) => {
    try {
      setLoading(true);
      const res = await driversService.getDrivers(
        limit,
        pageNum,
        currentOption,
        searchQuery
      );
      setDrivers(res?.data?.drivers);
      setTotalNum(res.data?.totalDrivers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve drivers",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (pageNum > 0) {
      getDrivers(itemsPerPage, pageNum, currentOption, searchQuery);
    }
  }, [pageNum, currentOption, searchQuery]);

  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [statistics, setStatistics] = useState({
    totalActiveDrivers: 0,
    totalPrivateDrivers: 0,
    totalInternalDrivers: 0,
    totalOnlineDrivers: 0,
  });

  const [boxes, setBoxes] = useState([
    {
      label: "Total Drivers",
      icon: <BsBarChartLine size={"30px"} color={"#3e98d4"} />,
      key: "totalActiveDrivers",
    },
    {
      label: "Online Drivers",
      icon: <HiUsers size={"30px"} color={"#0ED27D"} />,
      key: "totalOnlineDrivers",
    },
    {
      label: "Internal Drivers",
      icon: <MdOutlineStackedLineChart size={"30px"} color={"#F7A01F"} />,
      key: "totalInternalDrivers",
    },
    {
      label: "Private Drivers",
      icon: <LuLineChart size={"30px"} color={"#2B6FF2"} />,
      key: "totalPrivateDrivers",
    },
  ]);

  const getDriversStatistics = async (noload = false) => {
    try {
      setLoadingStatistics(noload ? false : true);
      const res = await driversService.getDriversStatistics();
      setStatistics({
        totalActiveDrivers: res?.data?.totalActiveDrivers,
        totalPrivateDrivers: res?.data?.totalPrivateDrivers,
        totalInternalDrivers: res?.data?.totalInternalDrivers,
        totalOnlineDrivers: res?.data?.totalOnlineDrivers,
      });
      setLoadingStatistics(false);
    } catch (error) {
      setLoadingStatistics(false);
    }
  };

  useEffect(() => {
    getDriversStatistics();
  }, []);

  const socket = useRef();
  socket.current = socketConnection;

  useEffect(() => {
    const statusChanged = (data) => {
      if (data?.userId) {
        if (drivers.some((d) => d?.id == data?.userId)) {
          setDrivers(
            drivers?.map((driver) => {
              if (driver?.id == data?.userId) {
                return {
                  ...driver,
                  availabilityStatus: data?.availabilityStatus,
                };
              } else {
                return { ...driver };
              }
            })
          );
          getDriversStatistics(false);
        }
      }
    };

    if (socket.current) {
      if (!socket.current?.connected) {
        socket?.current?.connect();
      }

      socket.current.on("statusChanged", statusChanged);
    }

    return () => {
      // Clean up event listeners when the component unmounts
      if (socket.current) {
        socket.current.off("statusChanged", statusChanged);
      }
    };
  }, [socket, drivers]);

  const filter = [
    { label: "All", value: null },
    { label: "Drivers with ride", value: "BUSY" },
    { label: "Drivers with no ride", value: "ONLINE" },
    { label: "Offline drivers", value: "OFFLINE" },
  ];

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Flex
          flexWrap={"wrap"}
          rowGap={"15px"}
          justifyContent={"space-between"}
          mb={"30px"}
          columnGap={"23px"}
        >
          {boxes?.map((box, index) => {
            return (
              <Box
                flex={1}
                key={"statistics_" + index}
                bg={"white"}
                p={"20px"}
                px={"40px"}
                rounded={"md"}
                boxShadow={"xs"}
              >
                <Box mb={"10px"}>{box?.icon}</Box>
                <Text color={"#788698"} fontSize={17}>
                  {box?.label}
                </Text>
                {loadingStatistics ? (
                  <Box pt={"5px"}>
                    <Spinner size={"md"} color={"#3e98d4"} />
                  </Box>
                ) : (
                  <Text color={"#000"} fontSize={22} fontWeight={"bold"}>
                    {statistics[box.key]?.toLocaleString()}
                  </Text>
                )}
              </Box>
            );
          })}
        </Flex>

        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Drivers</Text>
          </Box>

          <Box className="search-cont-panel">
            <Center mb={"10px"} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                className="search-field"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  const page = searchParams.get("page");
                  const status = searchParams.get("status") || null;
                  navigate(
                    `/drivers?page=${page}&status=${status}&searchKey=${e.target.value}`
                  );
                }}
              />
              <Text className={"search-icon"}>
                <CiSearch size={20} />
              </Text>
            </Center>

            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  h={"100% !important"}
                  fontSize={"16px"}
                  mr={"20px"}
                  paddingBottom={"0px"}
                  mt={"-4.5px"}
                  backgroundColor={"transparent !important"}
                  borderRadius={"0px !important"}
                  borderRight={"none !important"}
                  borderBottom={"none !important"}
                  border={"none !important"}
                  variant="outline"
                >
                  <Flex
                    py={"10px"}
                    px={"15px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    borderColor={"#F8FAFB"}
                    borderWidth={1}
                    alignItems={"center"}
                    columnGap={"10px"}
                  >
                    <Text>
                      {filter?.find((f) => f?.value == currentOption)?.label}
                    </Text>
                    <Text>
                      <CiFilter size={20}></CiFilter>
                    </Text>
                  </Flex>
                </MenuButton>
                <MenuList boxShadow="xl" rounded="md">
                  {filter?.map((_filter) => {
                    return (
                      <MenuItem
                        className={
                          currentOption == _filter?.value
                            ? "activeMenuList"
                            : ""
                        }
                        onClick={() => {
                          if (_filter?.value) {
                            navigate(
                              `/drivers?page=1&status=${_filter?.value}`
                            );
                          } else {
                            navigate(`/drivers?page=1&status=`);
                          }
                        }}
                        style={{ fontSize: 15 }}
                      >
                        <Flex columnGap={"15px"} alignItems={"center"}>
                          <Box
                            className={`active-dot ${
                              currentOption == _filter?.value
                                ? "active-dropdown"
                                : ""
                            }`}
                          ></Box>
                          {_filter?.label}
                        </Flex>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Box>

            <Button
              type={"button"}
              fontSize={"16px"}
              onClick={() => {
                setIsModalVisible(true);
              }}
              colorScheme="primary"
              loadingText={"Signing in..."}
              mb={"10px"}
              _hover={{ backgroundColor: "#3e98d4" }}
              color={"#fff"}
              display={"flex"}
              borderRadius={"10px"}
              columnGap={"5px"}
            >
              <AiFillPlusCircle size={18} color={"white"}></AiFillPlusCircle>
              &nbsp;Add Driver
            </Button>
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <DriversTable
            headers={headers}
            data={drivers}
            loading={loading}
            totalNum={totalNum}
            searchKey={searchQuery}
            status={currentOption}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            onReload={() => {
              getDrivers(itemsPerPage, pageNum, currentOption, searchQuery);
            }}
            onApprove={() => {
              getDriversStatistics();
              getDrivers(itemsPerPage, pageNum, currentOption, searchQuery);
            }}
            searching={false}
          />
        </Box>
      </Box>

      {isModalVisible && (
        <RegisterDriverModal
          onRegister={() => {
            toast({
              position: "top",
              title: "Account created!",
              description: "Driver registered successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            getDriversStatistics();
            setIsModalVisible(false);
            getDrivers(itemsPerPage, pageNum, currentOption, searchQuery);
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          isOpen={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default Drivers;
