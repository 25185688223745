import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
  Badge,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import { driversService } from "../../services/drivers";
import { Spinner } from "@chakra-ui/react";
import { adminService } from "../../services/admins";
import { IoSearch } from "react-icons/io5";

export default function AvailableDriversModal({
  isOpen,
  onOpen,
  onClose,
  onError,
  onSuccess,
  trip,
  times,
}) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [allDrivers, setAllDrivers] = useState([]);
  const [driversId, setDriversId] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const getDrivers = async (vehicleType) => {
    try {
      setLoading(true);
      const drivers = await driversService.matchingDrivers(vehicleType);
      console.log(drivers);
      setDrivers(drivers?.data?.drivers || []);
      setAllDrivers(drivers?.data?.drivers || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError("An error occured, please try again");
    }
  };

  const assignDriver = async () => {
    try {
      if (driversId.length == 0) return;
      setSaving(true);
      const res = await adminService.assignDriver({
        driversId: driversId,
        tripId: trip?.id,
      });
      setSaving(false);
      onSuccess("Drivers assigned successfully!");
    } catch (error) {
      setSaving(false);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    if (trip?.vehicleType) {
      getDrivers(trip?.vehicleType);
    }
  }, [trip, times]);

  useEffect(() => {
    if (searchKey != null) {
      if (searchKey?.trim()?.length == 0) {
        setDrivers(allDrivers);
      } else {
        setDrivers(
          allDrivers?.filter(
            (driver) =>
              driver?.fullName
                ?.toLowerCase()
                ?.includes(searchKey?.toLowerCase()) ||
              driver?.phoneNumber?.includes(searchKey)
          )
        );
      }
    }
  }, [searchKey]);

  return (
    <>
      <Modal
        scrollBehavior="inside"
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Assign Driver
            {!loading && allDrivers?.length > 0 && (
              <FormControl mt={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <IoSearch color="gray.300" />
                  </InputLeftElement>
                  <Input
                    autoFocus={true}
                    placeholder="Search by name or phone number"
                    value={searchKey}
                    onChange={(e) => {
                      setSearchKey(e.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading && (
              <Center py={"30px"}>
                <Spinner size={"lg"} color="#3e98d4" />
              </Center>
            )}
            {!loading &&
              drivers?.map((driver, index) => {
                return (
                  <Flex
                    onClick={() => {
                      setDriversId([driver?.id]);
                    }}
                    cursor={"pointer"}
                    py={"5px"}
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor:
                        index + 1 === drivers?.length ? "white" : "#E6EAEF",
                    }}
                    columnGap={"20px"}
                    alignItems={"center"}
                  >
                    <Radio
                      id={driver?.id}
                      onChange={(e) => {
                        setDriversId([driver?.id]);
                      }}
                      isChecked={driversId?.some((d) => d == driver?.id)}
                    />
                    <Image
                      objectFit={"contain"}
                      width={"70px"}
                      height={"50px"}
                      src={driver?.vehicles[0]?.images[0]}
                    />
                    <Box>
                      <Flex>
                        <Text>{driver?.fullName}</Text>
                        <Box>
                          <Badge
                            ml="1"
                            colorScheme={
                              driver?.availabilityStatus == "ONLINE"
                                ? "green"
                                : driver?.availabilityStatus === "OFFLINE"
                                ? "red"
                                : "orange"
                            }
                            textTransform={"capitalize"}
                          >
                            {driver?.availabilityStatus?.toLowerCase()}
                          </Badge>
                        </Box>
                      </Flex>
                      <Text>{driver?.phoneNumber}</Text>
                    </Box>
                  </Flex>
                );
              })}
            {!loading && drivers?.length == 0 && (
              <Center>
                <Text>No matching drivers available</Text>
              </Center>
            )}
            <ModalFooter mt={5}>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button
                isLoading={saving}
                isDisabled={driversId?.length == 0 ? true : false}
                onClick={assignDriver}
                colorScheme="primary"
              >
                Assign
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
