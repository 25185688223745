import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import { driversService } from "../../services/drivers";
import { Spinner } from "@chakra-ui/react";
import { adminService } from "../../services/admins";
import CountUp from "../count-up";

export default function RequestInfoModal({
  isOpen,
  onClose,
  onReject,
  onComplete,
  request,
  setDescription,
  description,
  onSave,
}) {
  const [loading, setLoading] = useState(false);
  const editSpecialRequestDescription = async () => {
    try {
      if (!description) return;

      setLoading(true);
      const res = await adminService.editSpecialRequestDescription({
        description: description,
        requestId: request?.id,
      });
      setLoading(false);
      onSave();
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("error occured");
    }
  };

  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Special Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Phone number:
              </Text>
              <Text color={"#000"} fontSize={16}>
                {request?.user.phoneNumber}
              </Text>
            </Box>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Full name:
              </Text>
              <Text color={"#000"} fontSize={16}>
                {request?.user.fullName}
              </Text>
            </Box>
            <Box mb={"10px"}>
              <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                Description:
              </Text>
              <Textarea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                defaultValue={request?.description}
              ></Textarea>
            </Box>

            {request?.status == "REJECTED" && (
              <Box>
                <Box mb={"10px"}>
                  <Text color={"#000"} fontWeight={"bold"} fontSize={16}>
                    Rejection reason:
                  </Text>
                  <Text color={"#000"} fontSize={16}>
                    {request?.rejectionReason}
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>

          <ModalFooter columnGap={"15px"}>
            {request?.description != description && (
              <Button
                isLoading={loading}
                loadingTex={"Saving..."}
                onClick={() => {
                  editSpecialRequestDescription();
                }}
                color={"white"}
                colorScheme="standard"
              >
                Save Changes
              </Button>
            )}
            {request?.status == "PENDING" && (
              <Flex columnGap={"15px"}>
                <Button
                  onClick={() => {
                    onReject();
                  }}
                  color={"white"}
                  colorScheme="danger"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onComplete();
                  }}
                  color={"white"}
                  colorScheme="primary"
                >
                  Assign Drivers
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
