import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Text,
  Box,
} from "@chakra-ui/react";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
const AppQrCode = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal size={"sm"} isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={"45px"}>
        <ModalBody py={"50px"} px={"0px"}>
          <Center>
            <Box>
              <Text
                mb={"20px"}
                textAlign={"center"}
                color={"#2F313F"}
                fontSize={"32px"}
              >
                {t("getWanaapp")}
              </Text>
              <Center my={"25px"}>
                <QRCodeSVG size={200} value="http://wanaapp.com/" />
              </Center>
              <Text
                px={"40px"}
                color={"#121d2b99"}
                fontSize={"14px"}
                fontWeight={"700"}
                textAlign={"center"}
              >
                {t("qrCodeDesc")}
              </Text>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AppQrCode;
