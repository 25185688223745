import axiosInstance from "../utils/axios";

export const adminService = {
  getAdmins: async (itemsPerPage, currentPage, searchQuery) => {
    try {
      let url = `/admins?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`;
      if (searchQuery) {
        url += `&searchQuery=${searchQuery}`;
      }
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  getPassengers: async (itemsPerPage, currentPage, searchQuery) => {
    try {
      let url = `/admins/passengers?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`;
      if (searchQuery) {
        url += `&searchQuery=${searchQuery}`;
      }
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  getPassengerStatistics: async () => {
    try {
      let url = `/admins/passengers/passenger-statistics`;
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  getTripsStatistics: async (startDate, endDate) => {
    try {
      let url = `/admins/tripsStatistics?startDate=${startDate}&endDate=${endDate}`;
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  assignDriver: async (data) => {
    try {
      return axiosInstance.put(`/admins/assignDriver`, data);
    } catch (error) {
      throw error;
    }
  },
  getDriversByTripId: async (id) => {
    try {
      return axiosInstance.get(`/admins/driversByTripId/${id}`);
    } catch (error) {
      throw error;
    }
  },
  rejectRequest: async (data) => {
    try {
      return axiosInstance.put(`/admins/specialRequests/reject`, data);
    } catch (error) {
      throw error;
    }
  },
  completeRequest: async (data) => {
    try {
      return axiosInstance.put(`/admins/specialRequests/complete`, data);
    } catch (error) {
      throw error;
    }
  },
  editSpecialRequestDescription: async (data) => {
    try {
      return axiosInstance.put(
        `/admins/specialRequests/editSpecialRequestDescription`,
        data
      );
    } catch (error) {
      throw error;
    }
  },
  updatePassword: async (data) => {
    try {
      return axiosInstance.put("/admins/updatePassword", data);
    } catch (error) {
      throw error;
    }
  },
  updatePricingDataStatus: async (data, id) => {
    try {
      return axiosInstance.put(
        `/admins/updatePricingDataStatus?id=${id}`,
        data
      );
    } catch (error) {
      throw error;
    }
  },
  editNameOrEmail: async (data) => {
    try {
      return axiosInstance.put(`/admins/editUserNameOrEmail`, data);
    } catch (error) {
      throw error;
    }
  },
  changeAccountStatus: async (data) => {
    try {
      return axiosInstance.put(`/admins/changeUserStatus`, data);
    } catch (error) {
      throw error;
    }
  },
};
