import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { authService } from "../../services/auth";
import PhoneInput from "react-phone-input-2";

export default function RegisterAdminModal({
  isOpen,
  onClose,
  onRegister,
  onError,
  action,
  currentUser,
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.username) {
      setData({
        email: currentUser?.email,
        username: currentUser?.username,
        phoneNumber: currentUser?.phoneNumber || "",
        role: currentUser?.role,
      });
    }
  }, [currentUser]);

  const userRoles = [
    { label: "SUPER ADMIN", value: "SUPER_ADMIN" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "SERVICE CLIENT", value: "SERVICE_CLIENT" },
    { label: "FINANCE", value: "FINANCE" },
    { label: "DISPATCH", value: "DISPATCH" },
    { label: "VEHICLE OWNER", value: "VEHICLE_OWNER" },
    { label: "SUPERVISOR", value: "SUPERVISOR" },
  ];

  const add = async (e) => {
    try {
      e.preventDefault();
      if (
        data.email.trim().length > 0 &&
        data.username.trim().length > 0 &&
        data?.phoneNumber &&
        data?.role
      ) {
        setLoading(true);
        if (action != "edit") {
          await authService.registerAdmin(data);
        } else {
          await authService.editAdmin({ ...data, id: currentUser?.id });
        }
        setLoading(false);
        return onRegister();
      } else {
        onError("Email, Phone number and username are required");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal isCentered={true} size={"lg"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {action == "edit" ? "Edit User" : "Register User"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={"100%"} onSubmit={add} as={"form"}>
            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Role
              </FormLabel>
              <Select
                fontWeight="medium"
                fontSize="14px"
                h={"45px"}
                mb={"2px"}
                value={data?.role}
                placeholder="Select user role"
                onChange={(e) => {
                  setData({ ...data, role: e.target.value });
                }}
              >
                {userRoles?.map((role, index) => {
                  return <option value={role?.value}>{role?.label}</option>;
                })}
              </Select>
            </FormControl>

            <FormControl mb={"10px"} isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Phone Number
              </FormLabel>
              <PhoneInput
                enableSearch={true}
                country={"cd"}
                placeholder="Enter phone number"
                value={data?.phoneNumber}
                containerClass="phone-number-container"
                buttonClass={"buttonClass"}
                inputClass={"phone-number-input"}
                inputStyle={{
                  width: "100%",
                  paddingTop: "22px",
                  paddingBottom: "22px",
                  fontSize: "16px",
                }}
                containerStyle={{ width: "100%" }}
                onChange={(val) => {
                  setData({ ...data, phoneNumber: val });
                }}
              />
            </FormControl>

            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Email address
              </FormLabel>
              <Input
                py={"22px"}
                value={data?.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                type={"email"}
                placeholder="Email address"
              />
            </FormControl>

            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Username
              </FormLabel>
              <Input
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, username: e.target.value });
                }}
                type={"text"}
                value={data?.username}
                placeholder="Username"
              />
            </FormControl>

            <ModalFooter>
              <Button type={"button"} mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                loadingText={"Saving..."}
                type={"submit"}
                colorScheme="primary"
              >
                Save
              </Button>
            </ModalFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
