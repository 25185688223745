import {
  Box,
  Flex,
  Image,
  Button,
  Text,
  Link,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
  Menu,
  Center,
} from "@chakra-ui/react";
import logo from "../../assets/images/white-logo.png";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { useState } from "react";
import AppQrCode from "./appqrcode";
import { FiChevronDown } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";

export default function AppNavBar({ color, currentPage }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const appLanguages = [
    {
      label: "France",
      prefix: "FR",
      flag: (
        <ReactCountryFlag
          svg
          style={{
            width: "20px",
            height: "20px",
            borderRadius: 100,
            objectFit: "cover",
          }}
          countryCode="FR"
        />
      ),
    },
    {
      label: "English",
      prefix: "EN",
      flag: (
        <ReactCountryFlag
          svg
          style={{
            width: "20px",
            height: "20px",
            borderRadius: 100,
            objectFit: "cover",
          }}
          countryCode="GB"
        />
      ),
    },
  ];

  const [language, setLanguage] = useState(
    appLanguages[localStorage.getItem("lang") == "en" ? 1 : 0]
  );
  const [languages, setLanguages] = useState(appLanguages);

  return (
    <Box>
      <Box
        bg={color ? color : undefined}
        px={"150px"}
        display={"flex"}
        justifyContent={"space-between"}
        className="appnavbar"
        alignItems={"center"}
        h="65px"
        w="100%"
      >
        <Link className="imgcont" href="https://www.wanaapp.com">
          <Image h={"50px"} src={logo} />
        </Link>

        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          className="links-cont"
          columnGap={"30px"}
        >
         <Box className="langCont">
         <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              h={"100% !important"}
              paddingBottom={"0px"}
              backgroundColor={"transparent !important"}
              borderRadius={"0px !important"}
              border={"none !important"}
              variant="outline"
            >
              <Flex
                fontSize={"14px"}
                fontWeight={"500"}
                position={"relative"}
                top={"1px"}
                className="applanguagebtn"
              >
                <Center
                  h={"100% !important"}
                  px={"10px"}
                  border={"none !important"}
                >
                  {language?.flag}
                  <Box ml={"5px"}>
                    <Text fontWeight={"900"} color={"#fff"}>
                      {language?.prefix}
                    </Text>
                  </Box>
                  <Text>
                    <FiChevronDown color="#fff" size={22}></FiChevronDown>
                  </Text>
                </Center>
              </Flex>
            </MenuButton>

            <MenuList boxShadow="xl" rounded="md">
              {languages?.map((lang) => {
                return (
                  <MenuItem
                    onClick={() => {
                      localStorage.setItem("lang", lang.prefix?.toLowerCase());
                      setLanguage(lang);
                      window.location.reload();
                    }}
                    style={{ fontSize: 15 }}
                  >
                    <Flex columnGap={"10px"} alignItems={"center"}>
                      {lang?.flag}
                      {lang?.label}
                    </Flex>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
         </Box>

          <Link
            _hover={{
              textDecoration: "none",
              color: "#AFAFAF",
            }}
            textDecoration={"none"}
            href="/policies/terms"
          >
            <Text
              className="privacylink"
              fontWeight={"700"}
              color={currentPage == "tos" ? "#3e98d4" : "#fff"}
              fontSize={"15px"}
              _hover={{
                textDecoration: "none",
                color: "#AFAFAF",
              }}
            >
              {t("privacyPolicy")}
            </Text>
          </Link>

          <Link
            textDecoration={"none"}
            _hover={{ textDecoration: "none", color: "#AFAFAF" }}
            href="#"
          >
            <Text
              display={"flex"}
              columnGap={"4px"}
              fontWeight={"700"}
              color={"#fff"}
              fontSize={"15px"}
              className="helplink"
            >
              <IoMdHelpCircleOutline size={"22px"} />
              {t("help")}
            </Text>
          </Link>

          <Button
            bg={"white"}
            borderRadius={"100px"}
            color={"#000"}
            fontSize={"15px"}
            onClick={() => {
              setIsOpen(true);
            }}
            fontWeight={"700"}
          >
            {t("getTheApp")}
          </Button>
        </Flex>
      </Box>
      <AppQrCode
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </Box>
  );
}
