import { Box, Text, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import SpecialRequestsTable from "./special-requests-table";
import { socketConnection } from "../../utils/socket";

export default function SpecialRequests({ currentOption, startDate, endDate }) {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  const headers = [
    {
      name: "Passenger",
    },
    {
      name: "Amount",
    },
    {
      name: "Status",
    },
    {
      name: "Description",
    },
    {
      name: "Created at",
      sortable: true,
    },
    {
      name: "Action",
    },
  ];

  useEffect(() => {
    const page = searchParams.get("page");
    const tab = searchParams.get("tab");
    const status = searchParams.get("status");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate(
      `/trips?page=1&tab=${tab}&status=${status ? status : "ALL"}`
    );
  }, [searchParams]);

  const getRequests = async (limit, pageNum, noLoad = false) => {
    try {
      setLoading(noLoad == true ? false : true);
      const res = await tripsService.geSpecialRequests(
        limit,
        pageNum,
        currentOption,
        startDate,
        endDate
      );
      setRequests(res?.data?.requests);
      setTotalNum(res.data?.totalRequests);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message ||
          "Failed to retrieve special requests",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (currentOption) {
      getRequests(itemsPerPage, pageNum);
    }
  }, [pageNum, currentOption, startDate, endDate]);

  const socket = useRef();
  socket.current = socketConnection;

  const refreshData = () => {
    const btn = document.getElementById("refreshBookinsBtn");
    if (btn) {
      btn.click();
    }
  };

  useEffect(() => {
    const newRequest = () => {
      refreshData();
      getRequests(itemsPerPage, pageNum, true);
    };

    if (socket.current) {
      if (!socket.current?.connected) {
        socket?.current?.connect();
      }

      socket.current.on("new_special_request", newRequest);
    }

    return () => {
      if (socket.current) {
        socket.current.off("new_special_request", newRequest);
      }
    };
  }, [socket, itemsPerPage, pageNum]);

  return (
    <>
      <Box mx="0px">
        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <SpecialRequestsTable
            headers={headers}
            data={requests}
            loading={loading}
            totalNum={totalNum}
            refetch={() => {
              getRequests(itemsPerPage, pageNum);
            }}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
            status={"ALL"}
            onReload={() => {
              getRequests(itemsPerPage, pageNum);
            }}
          />
        </Box>
      </Box>
    </>
  );
}
