import TripsTable from "./tripsTable";
import { useEffect, useRef, useState } from "react";
import { useToast, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import { socketConnection } from "../../utils/socket";

const BookingsTab = ({ currentOption, startDate, endDate }) => {
  const itemsPerPage = 10;
  const [pageNum, setPageNum] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  useEffect(() => {
    const tab = searchParams.get("tab");
    const page = searchParams.get("page");
    const status = searchParams.get("status");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate(
      `/trips?page=1&tab=${tab}&status=${status ? status : "ALL"}`
    );
  }, [searchParams]);

  const headers = [
    {
      name: "Trip Date",
      sortable: true,
    },
    {
      name: "Passenger",
    },
    {
      name: "Pickup",
    },
    {
      name: "Drop-off",
    },
    {
      name: "Vehicle",
    },
    {
      name: "Amount",
    },
    {
      name: "Trip Status",
    },
    {
      name: "Ride Type",
    },
    {
      name: "Action",
    },
  ];

  const getTrips = async (limit, pageNum, noLoad = false) => {
    try {
      setLoading(noLoad == true ? false : true);
      const res = await tripsService.getTrips(
        limit,
        pageNum,
        "LATER",
        currentOption,
        startDate,
        endDate
      );
      console.log(res.data.trips);
      setTrips(res?.data?.trips);
      setTotalNum(res.data?.totalTrips);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve trips",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (currentOption) {
      getTrips(itemsPerPage, pageNum);
    }
  }, [pageNum, currentOption, startDate, endDate]);

  const socket = useRef();
  socket.current = socketConnection;

  const refreshPage = () => {
    getTrips(itemsPerPage, pageNum, true);
  };

  const refreshData = () => {
    const btn = document.getElementById("refreshBookinsBtn");
    if (btn) {
      btn.click();
    }
  };

  useEffect(() => {
    const newRequest = () => {
      refreshData();
      getTrips(itemsPerPage, pageNum, true);
    };

    const refetchBookings = () => {
      refreshData();
      getTrips(itemsPerPage, pageNum, true);
    };

    if (socket.current) {
      if (!socket.current?.connected) {
        socket?.current?.connect();
      }

      socket.current.on("newTripBooked", newRequest);
      socket.current.on("driverAutoAssigned", refetchBookings);
    }
    return () => {
      if (socket.current) {
        socket.current.off("newTripBooked", newRequest);
        socket.current.off("driverAutoAssigned", refetchBookings);
      }
    };
  }, [socket, itemsPerPage, pageNum]);

  return (
    <>
      <Box mx="0px">
        <button
          id="refreshBookings"
          style={{ backgroundColor: "green", display: "none" }}
          onClick={() => {
            refreshPage();
          }}
        ></button>
        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <TripsTable
            headers={headers}
            data={trips}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            status={currentOption}
            onReload={() => {
              getTrips(itemsPerPage, pageNum);
            }}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default BookingsTab;
