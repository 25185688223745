import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  FormLabel,
  Image,
  Tbody,
  Td,
  Table,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiUser, BiUserPlus } from "react-icons/bi";
// import jwt_decode from "jwt-decode";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsCamera } from "react-icons/bs";
import PricingInfo from "./pricing-info";
import { IoPricetagOutline } from "react-icons/io5";
import { adminService } from "../../services/admins";
import { useNavigate } from "react-router-dom";
import useAuth from "../../contexts/useAuth";
import ConstantsTable from "./constants";
import { VscSymbolConstant } from "react-icons/vsc";
import RequestReasons from "./requestsReasons";

const Settings = () => {
  const toast = useToast();
  const [decodedHeader, setDecodedHeader] = useState({});
  const navigate = useNavigate();
  const [updatingInfo, setUpdatingInfo] = useState(false);
  const { user, getCurrentUser } = useAuth();
  const [formData3, setFormData3] = useState({
    username: "",
    email: "",
  });

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (user?.email) {
      setFormData3({
        username: user.username,
        email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    const token = window.localStorage.getItem("access_token");
    // setDecodedHeader(jwt_decode(token));
  }, []);
  const [updating, setUpdating] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const [updating2, setUpdating2] = useState(false);
  const [updating3, setUpdating3] = useState(false);

  const [formData2, setFormData2] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const addAdmin = async (e) => {
    try {
    } catch (error) {
      setUpdating(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      setUpdating2(true);
      const res = await adminService.updatePassword(formData2);
      setUpdating2(false);

      setFormData2({ currentPassword: "", newPassword: "" });
      toast({
        title: "Success",
        description: res?.data?.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      const token = localStorage.getItem("access_token");
      navigate("/logout");
    } catch (error) {
      setUpdating2(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const editUser = async () => {
    try {
      setUpdatingInfo(true);
      //  updatingInfo
      const name = formData3.username?.trim();
      const email = formData3?.email?.trim();
      if (!name || !email) {
        alert("Username and email are required");
        return;
      }

      const res = await adminService.editNameOrEmail({
        username: name,
        email: user.role == "SUPER_ADMIN" ? email : user?.email,
      });
      const btn = document.getElementById("getCurrentUser");
      if (btn) {
        btn.click();
      }
      setUpdatingInfo(false);
    } catch (error) {
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      setUpdatingInfo(false);
    }
  };

  const changeAccountInfo = async (e) => {
    try {
      // e.preventDefault();
      // try {
      //   setUpdating3(true);
      //   let imageUpdates = {
      //     photo: formData3?.photo,
      //     publicId: formData3?.publicId,
      //   };
      //   if (userInfo?.photo != formData3?.photo) {
      //     const CLOUDINARY_URL =
      //       "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";
      //     let data = {
      //       file: formData3?.photo,
      //       upload_preset: "s1ef0r8m",
      //     };
      //     const r = await fetch(CLOUDINARY_URL, {
      //       body: JSON.stringify(data),
      //       headers: {
      //         "content-type": "application/json",
      //       },
      //       method: "POST",
      //     });
      //     let imageInfo = await r.json();
      //     imageUpdates = {
      //       photo: imageInfo?.url,
      //       publicId: imageInfo?.public_id,
      //     };
      //     setFormData3({
      //       ...formData3,
      //       photo: imageInfo?.url,
      //       publicId: imageInfo?.public_id,
      //     });
      //   }
      //   const res = await changeProfile({ ...formData3, ...imageUpdates });
      //   setUpdating3(false);
      //   if (res?.data?.status == true) {
      //     window.localStorage.setItem("access_token", res?.data?.access_token);
      //     getLoggedInUser();
      //     const toggleUserInfo = document.getElementById("toggleUserInfo");
      //     if (toggleUserInfo) {
      //       toggleUserInfo.click();
      //     }
      //     toast({
      //       title: "Success",
      //       description: res?.data?.message,
      //       status: "success",
      //       duration: 2000,
      //       isClosable: true,
      //       position: "top-right",
      //     });
      //   }
    } catch (error) {
      setUpdating3(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadFile = function (event) {
    const reader = new FileReader();
    let file = event.target.files[0];
    reader.addEventListener(
      "load",
      () => {
        setFormData3({ ...formData3, photo: reader.result });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [admins, setAdmins] = useState([]);

  const getAllAdmins = async () => {
    // const res = await getAdmins();
    // setAdmins(res?.data?.admins);
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  return (
    <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
      <Box>
        <Box mb={"20px"} className="table-nav">
          <Box className="tb-title">
            <Text>Settings</Text>
          </Box>
        </Box>

        <Box mb={"40px"} rounded={"xl"} w={"full"}>
          <Accordion defaultIndex={[0]} allowToggle>
            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#3e98d4" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <IoPricetagOutline
                          color={isExpanded ? "white" : "#000"}
                          size={26}
                        />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box
                          fontSize={"18px"}
                          color={isExpanded ? "white" : "#000"}
                        >
                          Pricing Information
                        </Box>
                        <Box
                          fontWeight={"normal"}
                          fontSize={"17px"}
                          as="p"
                          flex="1"
                          textAlign="left"
                          color={isExpanded ? "white" : "text.light"}
                        >
                          Manage pricing info
                        </Box>
                      </Box>

                      <AccordionIcon color={isExpanded ? "white" : "#000"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <PricingInfo />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#3e98d4" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <VscSymbolConstant
                          color={isExpanded ? "white" : "#000"}
                          size={26}
                        />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box
                          fontSize={"18px"}
                          color={isExpanded ? "white" : "#000"}
                        >
                          Constants
                        </Box>
                        <Box
                          fontWeight={"normal"}
                          fontSize={"17px"}
                          as="p"
                          flex="1"
                          textAlign="left"
                          color={isExpanded ? "white" : "text.light"}
                        >
                          Manage app constants
                        </Box>
                      </Box>

                      <AccordionIcon color={isExpanded ? "white" : "#000"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <ConstantsTable />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#3e98d4" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <VscSymbolConstant
                          color={isExpanded ? "white" : "#000"}
                          size={26}
                        />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box
                          fontSize={"18px"}
                          color={isExpanded ? "white" : "#000"}
                        >
                          Requests cancel or reject reasons
                        </Box>
                        <Box
                          fontWeight={"normal"}
                          fontSize={"17px"}
                          as="p"
                          flex="1"
                          textAlign="left"
                          color={isExpanded ? "white" : "text.light"}
                        >
                          Manage requests cancel or reject reasons
                        </Box>
                      </Box>

                      <AccordionIcon color={isExpanded ? "white" : "#000"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <RequestReasons />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#3e98d4" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <BiUser
                          color={isExpanded ? "white" : "#000"}
                          size={26}
                        />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box
                          fontSize={"18px"}
                          color={isExpanded ? "white" : "#000"}
                        >
                          Account Info
                        </Box>
                        <Box
                          fontWeight={"normal"}
                          fontSize={"17px"}
                          color={isExpanded ? "white" : "text.light"}
                          as="p"
                          flex="1"
                          textAlign="left"
                        >
                          Change account information
                        </Box>
                      </Box>

                      <AccordionIcon color={isExpanded ? "white" : "#000"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <Box
                      onSubmit={changeAccountInfo}
                      as={"form"}
                      py={"10px"}
                      pt={"20px"}
                    >
                      <FormControl mb={"10px"} isRequired>
                        <FormLabel
                          fontWeight="medium"
                          fontSize="14px"
                          mb={"2px"}
                        >
                          Email
                        </FormLabel>
                        <Input
                          onChange={(e) => {
                            setFormData3({
                              ...formData3,
                              email: e.target.value,
                            });
                          }}
                          py={"20px"}
                          readOnly={user.role == "SUPER_ADMIN" ? false : true}
                          type={"email"}
                          value={formData3?.email}
                          placeholder="Email address"
                        />
                      </FormControl>
                      <FormControl mb={"16px"} isRequired>
                        <FormLabel
                          fontWeight="medium"
                          fontSize="14px"
                          mb={"2px"}
                        >
                          Username
                        </FormLabel>
                        <Input
                          onChange={(e) => {
                            setFormData3({
                              ...formData3,
                              username: e.target.value,
                            });
                          }}
                          py={"20px"}
                          type={"text"}
                          value={formData3?.username}
                          placeholder="Enter new username"
                        />
                      </FormControl>

                      {(user?.username != formData3?.username ||
                        user?.email != formData3?.email) && (
                        <Box>
                          <Button
                            loadingText={"Saving..."}
                            isLoading={updatingInfo}
                            type={"button"}
                            colorScheme="primary"
                            onClick={() => {
                              editUser();
                            }}
                          >
                            Save changes
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#3e98d4" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <RiLockPasswordLine
                          color={isExpanded ? "white" : "#000"}
                          size={26}
                        />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box
                          fontSize={"18px"}
                          color={isExpanded ? "white" : "#000"}
                        >
                          {" "}
                          Change password
                        </Box>
                        <Box
                          fontWeight={"normal"}
                          fontSize={"17px"}
                          color={isExpanded ? "white" : "text.light"}
                          as="p"
                          flex="1"
                          textAlign="left"
                        >
                          *********
                        </Box>
                      </Box>

                      <AccordionIcon color={isExpanded ? "white" : "#000"} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <Box onSubmit={changePassword} as={"form"} py={"10px"}>
                      <FormControl mb={"12px"} isRequired>
                        <FormLabel
                          fontWeight="medium"
                          fontSize="14px"
                          mb={"2px"}
                        >
                          Current Password
                        </FormLabel>
                        <Input
                          onChange={(e) => {
                            setFormData2({
                              ...formData2,
                              currentPassword: e.target.value,
                            });
                          }}
                          py={"20px"}
                          type={"password"}
                          value={formData2?.currentPassword}
                          placeholder="Enter current password"
                        />
                      </FormControl>

                      <FormControl mb={"20px"} isRequired>
                        <FormLabel
                          fontWeight="medium"
                          fontSize="14px"
                          mb={"2px"}
                        >
                          New Password
                        </FormLabel>
                        <Input
                          onChange={(e) => {
                            setFormData2({
                              ...formData2,
                              newPassword: e.target.value,
                            });
                          }}
                          py={"20px"}
                          type={"password"}
                          value={formData2?.newPassword}
                          placeholder="Enter current password"
                        />
                      </FormControl>

                      <Box>
                        <Button
                          loadingText={"Updating..."}
                          isLoading={updating2 == true ? true : false}
                          type={"submit"}
                          colorScheme="primary"
                        >
                          Change Password
                        </Button>
                      </Box>
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
