import AdminsTable from "./adminsTable";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { Text, Input, useToast, Box, Center, Button } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterAdminModal from "../../components/common/registerAdminModal";
import { adminService } from "../../services/admins";

const Admins = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [totalNum, setTotalNum] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const page = searchParams.get("page");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/users?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "User",
    },
    {
      name: "Email",
    },
    {
      name: "Role",
    },
    {
      name: "Status",
    },
    {
      name: "Created At",
      sortable: true,
    },
    {
      name: "Action",
    },
  ];

  const getAdmins = async (limit, pageNum,searchQuery) => {
    try {
      setLoading(true);
      const res = await adminService.getAdmins(limit, pageNum,searchQuery);
      setAdmins(res?.data?.admins);
      setTotalNum(res.data?.totalAdmins);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve admins",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getAdmins(itemsPerPage, pageNum,searchQuery);
  }, [pageNum, searchQuery]);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Users</Text>
          </Box>

          <Box className="search-cont-panel">
            <Center mb={"10px"} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                value={searchQuery}
                className="search-field"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <Text className={"search-icon"}>
                <CiSearch size={20} />
              </Text>
            </Center>

            <Button
              type={"button"}
              fontSize={"16px"}
              onClick={() => {
                setIsModalVisible(true);
              }}
              colorScheme="primary"
              loadingText={"Signing in..."}
              mb={"10px"}
              _hover={{ backgroundColor: "#3e98d4" }}
              color={"#fff"}
              display={"flex"}
              borderRadius={"10px"}
              columnGap={"5px"}
            >
              <AiFillPlusCircle size={18} color={"white"}></AiFillPlusCircle>
              &nbsp;Add User
            </Button>
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <AdminsTable
            headers={headers}
            data={admins}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
            onReload={() => {
              getAdmins(itemsPerPage, pageNum,searchQuery);
            }}
          />
        </Box>
      </Box>

      {isModalVisible && (
        <RegisterAdminModal
          onRegister={() => {
            toast({
              position: "top",
              title: "Account created!",
              description: "User registered successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsModalVisible(false);
            getAdmins(itemsPerPage, pageNum,searchQuery);
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          isOpen={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
          }}
          onReload={() => {
            getAdmins(itemsPerPage, pageNum,searchQuery);
          }}
        />
      )}
    </>
  );
};

export default Admins;
