import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { vehiclesService } from "../../services/vehicles";
import { vehicleTypes } from "../../utils/helpers";

export default function VehiclesModal({
  isOpen,
  onClose,
  onError,
  onSuccess,
  driverId,
}) {
  const [loading, setLoading] = useState(true);
  const [assigning, setAssigning] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();

  const getVehicles = async () => {
    try {
      setLoading(true);
      const drivers = await vehiclesService.getAllVehicles(
        null,
        null,
        "NOT_ASSIGNED",
        null
      );
      setVehicles(drivers?.data?.vehicles || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError("An error occured, please try again");
    }
  };

  const assign = async (vehicleId) => {
    try {
      setAssigning(true);
      const res = await vehiclesService.assignDriver({
        vehicleId: vehicleId,
        driverId: driverId,
      });
      setAssigning(false);
      onSuccess(res?.data?.message);
    } catch (error) {
      console.log(error);
      setAssigning(false);
      onError(
        error?.response?.data?.message || "An error occured, please try again"
      );
    }
  };

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <>
      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Vehicle</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading ? (
              <Center py={"30px"}>
                <Spinner size={"lg"} color="#3e98d4" />
              </Center>
            ) : (
              <Box>
                {vehicles?.length > 0 ? (
                  vehicles?.map((vehicle, index) => {
                    return (
                      <Box
                        _hover={{
                          bg:
                            selectedVehicle == vehicle?.id
                              ? "white"
                              : "neutral.300",
                        }}
                        key={vehicle.id}
                      >
                        <Flex
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Radio
                            flex={"1"}
                            isChecked={selectedVehicle == vehicle?.id}
                            onChange={(e) => {
                              if (selectedVehicle != vehicle?.id) {
                                setSelectedVehicle(vehicle?.id);
                              }
                            }}
                            w={"100%"}
                            cursor={"pointer"}
                            py={"10px"}
                            size={"lg"}
                            colorScheme="primary"
                          >
                            <Flex alignItems={"center"} columnGap={"25px"}>
                              <Image
                                w={"60px"}
                                height={"50px"}
                                objectFit={"contain"}
                                src={vehicle?.images[0]}
                              />
                              <Box>
                                <Text fontSize={"16px"} fontWeight={"500"}>
                                  {vehicle?.vehicle?.vehicleType}
                                </Text>
                                <Flex columnGap={"10px"}>
                                  <Text fontSize={"16px"}>
                                    {vehicle?.plateNumber}
                                  </Text>
                                </Flex>
                                <Text fontSize={"16px"} color="neutral.700">
                                  {vehicle?.model}
                                </Text>
                              </Box>
                            </Flex>
                          </Radio>

                          {selectedVehicle == vehicle?.id && (
                            <Button
                              colorScheme="success"
                              type={"button"}
                              isLoading={assigning}
                              px={"20px"}
                              loadingText={"Assigning..."}
                              size={"sm"}
                              pos={"relative"}
                              top={"13px"}
                              onClick={() => {
                                assign(selectedVehicle);
                              }}
                            >
                              Assign
                            </Button>
                          )}
                        </Flex>
                        {index + 1 != vehicles?.length && <Divider />}
                      </Box>
                    );
                  })
                ) : (
                  <Center>
                    <Text>No available vehicles found</Text>
                  </Center>
                )}
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
