import {
  Box,
  Link,
  Heading,
  Text,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import { useState } from "react";
import AppNavBar from "./navbar";

export default function Tos() {
  return (
    <Box className="w-full">
      <AppNavBar currentPage={"tos"} color={"rgb(28 36 52)"} />

      <Box className="tospagecontainer" px={"150px"} mb={"50px"}>
        <Box my={6} mb={5} py={4}>
          <Heading as="h2" size="xl" mb={3}>
            Conditions Générales dʼUtilisation
          </Heading>
          <Text fontSize="base" fontStyle={"italic"} color="gray.600">
            Dernière mise à jour: 12 Décembre, 2024
          </Text>
        </Box>

        <OrderedList pl={"5px"} className="toslist" spacing={4}>
          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Services proposés
            </Heading>
            <Text>
              Wana fournit une plateforme technologique permettant de connecter
              les utilisateurs (passagers) avec des chauffeurs indépendants ou
              des prestataires de services de transport.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Utilisation de la plateforme
            </Heading>
            <Text>
              Vous devez être majeur et au moins à lʼâge de 18 ans pour utiliser
              Wana et fournir des informations exactes lors de votre
              inscription. Votre compte est personnel et ne peut être partagé.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Paiement
            </Heading>
            <Text>
              Les tarifs des trajets sont calculés automatiquement via
              lʼapplication. Vous acceptez de payer les frais indiqués, y
              compris les taxes et éventuels frais supplémentaires.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Responsabilité
            </Heading>
            <Text>
              Wana agit uniquement comme intermédiaire. Wana nʼest pas
              responsable des actions ou omissions des chauffeurs ou
              prestataires de services.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Comportement des utilisateurs
            </Heading>
            <Text>
              Les utilisateurs doivent respecter les chauffeurs et les
              conditions de sécurité. Tout comportement inapproprié peut
              entraîner la suspension ou la résiliation du compte.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Modifications des services
            </Heading>
            <Text>
              Wana se réserve le droit de modifier ou de suspendre ses services
              à tout moment.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Données personnelles
            </Heading>
            <Text>
              Wana collecte et traite vos données conformément à sa politique de
              confidentialité.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Résiliation
            </Heading>
            <Text>
              Wana peut suspendre ou résilier votre accès à la plateforme en cas
              de violation des présentes conditions.
            </Text>
          </ListItem>

          <ListItem>
            <Heading as="h3" fontSize="24px" mb={2}>
              Litiges
            </Heading>
            <Text>
              Tout litige sera soumis aux lois locales applicables et à la
              juridiction compétente.
            </Text>
          </ListItem>

          <ListItem className="last-item">
            <Heading as="h3" fontSize="24px" mb={2}>
              Plus dʼinfos
            </Heading>
            <Text>
              Pour les détails complets, consultez les conditions générales
              complètes sur le site officiel Wana à{" "}
              <Link
                href="http://wanaapp.com/"
                color={"blue"}
                textDecoration={"underline"}
              >
                www.wanaapp.com
              </Link>
            </Text>
          </ListItem>
        </OrderedList>
      </Box>
    </Box>
  );
}
