import PassengersTable from "./passengersTable";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import {
  Text,
  Input,
  useToast,
  Box,
  Center,
  Button,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { tripsService } from "../../services/trips";
import { adminService } from "../../services/admins";
import { IoIosToday } from "react-icons/io";
import { MdCalendarMonth } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import { FcStatistics } from "react-icons/fc";
import { FaDatabase } from "react-icons/fa6";
import { MdViewWeek } from "react-icons/md";
import { CgViewMonth } from "react-icons/cg";

const Passengers = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [totalNum, setTotalNum] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const page = searchParams.get("page");

    const key = searchParams.get("searchKey");
    if (key) {
      setSearchQuery(key);
    } else {
      setSearchQuery(null);
    }

    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/passengers?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "Username",
    },
    {
      name: "Phone Number",
    },
    {
      name: "Email",
    },
    {
      name: "Total Trips",
    },
    {
      name: "Status",
    },
    {
      name: "Created At",
    },
    {
      name: "Action",
    },
  ];

  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [statistics, setStatistics] = useState({});
  const statisticsData = [
    {
      label: "Today",
      value: 0,
      icon: <IoIosToday size={"30px"} color={"#3e98d4"} />,
      key: "today",
    },
    {
      label: "Yesterday",
      value: 0,
      icon: <GrPrevious size={"30px"} color={"#E34747"} />,
      key: "yesterday",
    },
    {
      label: "This Week",
      value: 0,
      icon: <FcStatistics size={"30px"} />,
      key: "thisWeek",
    },
    {
      label: "Last Week",
      value: 0,
      icon: <MdViewWeek size={"30px"} color={"#F7A01F"} />,
      key: "lastWeek",
    },
    {
      label: "This Month",
      value: 0,
      icon: <MdCalendarMonth size={"30px"} color={"#0ED27D"} />,
      key: "thisMonth",
    },
    {
      label: "Last Month",
      value: 0,
      icon: <CgViewMonth size={"30px"} color={"#2B6FF2"} />,
      key: "lastMonth",
    },
    {
      label: "Total",
      value: 0,
      icon: <FaDatabase size={"30px"} color={"green"} />,
      key: "allTimes",
    },
  ];

  const getStatistics = async () => {
    try {
      setLoadingStatistics(true);
      const res = await adminService.getPassengerStatistics();
      console.log("res", res?.data);
      setStatistics(res?.data);
      setLoadingStatistics(false);
    } catch (error) {
      setLoadingStatistics(false);
    }
  };

  const getPassengers = async (limit, pageNum) => {
    try {
      setLoading(true);
      const res = await adminService.getPassengers(limit, pageNum, searchQuery);
      setTrips(res?.data?.passengers);
      setTotalNum(res.data?.totalPassengers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve passengers",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    getPassengers(itemsPerPage, pageNum, searchQuery);
  }, [pageNum, searchQuery]);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Flex
          flexWrap={"no-wrap"}
          rowGap={"15px"}
          justifyContent={"space-between"}
          mb={"30px"}
          columnGap={"15px"}
        >
          {statisticsData?.map((box, index) => {
            return (
              <Box
                flex={1}
                key={"statistics_" + index}
                bg={"white"}
                p={"20px"}
                px={"15px"}
                rounded={"md"}
                boxShadow={"xs"}
              >
                <Box mb={"10px"}>{box?.icon}</Box>
                <Text
                  isTruncated={true}
                  fontWeight={"500"}
                  color={"#788698"}
                  fontSize={15}
                >
                  {box?.label}
                </Text>
                {loadingStatistics ? (
                  <Box pt={"5px"}>
                    <Spinner size={"md"} color={"#3e98d4"} />
                  </Box>
                ) : (
                  <Text color={"#000"} fontSize={22} fontWeight={"bold"}>
                    {statistics[box.key]?.toLocaleString()}
                  </Text>
                )}
              </Box>
            );
          })}
        </Flex>

        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Clients</Text>
          </Box>

          <Box className="search-cont-panel">
            <Center mb={"10px"} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                className="search-field"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  const page = searchParams.get("page");
                  navigate(
                    `/passengers?page=${page}&searchKey=${e.target.value}`
                  );
                }}
              />
              <Text zIndex={9999} right={9} position={"absolute"}>
                <CiSearch size={20} />
              </Text>
            </Center>
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <PassengersTable
            headers={headers}
            data={trips}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            onReload={() => {
              getPassengers(itemsPerPage, pageNum, searchQuery);
            }}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default Passengers;
