import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Center,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { authService } from "../../services/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { driversService } from "../../services/drivers";
import { IoMdClose } from "react-icons/io";
import { vehiclesService } from "../../services/vehicles";

export default function RegisterVehicleModal({
  isOpen,
  onClose,
  onRegister,
  onError,
  action = "register",
  currentVehicle = {},
  onChangeDriver,
  onCloseShift
}) {
  const [data, setData] = useState({});
  const currentYear = new Date().getFullYear();
  const startYear = 2005;
  const [loading, setLoading] = useState(false);
  const imageInput = useRef();
  const [vehicleImages, setVehicleImages] = useState([]);
  const cloudinaryUrl =
    "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";

  useEffect(() => {
    if (currentVehicle?.vehicleType) {
      const {
        color,
        seats,
        plateNumber,
        model,
        brand,
        fabricationYear,
        withLogo,
        chasisNumber,
        insuranceCompany,
        insuranceExpirationDate,
        images,
      } = currentVehicle;
      setVehicleImages(images);
      setData({
        color,
        vehicleType: currentVehicle?.vehicle?.id,
        seats,
        plateNumber,
        model,
        brand,
        fabricationYear,
        withLogo,
        chasisNumber,
        insuranceCompany,
        insuranceExpirationDate,
        images,
      });
    }
  }, [currentVehicle]);

  const add = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (vehicleImages?.length == 0) {
        onError("Upload atleast one image of the vehicle");
        setLoading(false);
        return;
      }

      let uploadedImages = [];

      if (vehicleImages?.length > 0) {
        console.log("vehicleImages", vehicleImages);
        const uploadPromises = vehicleImages.map(async (image) => {
          if (image.startsWith("https://")) {
            return image;
          }

          const formData = new FormData();
          formData.append("file", image);
          formData.append("upload_preset", "wtmnkwiw");

          const response = await fetch(cloudinaryUrl, {
            method: "POST",
            body: formData,
          });
          const result = await response.json();
          return result.secure_url;
        });

        uploadedImages = await Promise.all(uploadPromises);
        console.log("uploadedImages", uploadedImages);
      }

      const {
        color,
        vehicleType,
        seats,
        plateNumber,
        model,
        brand,
        fabricationYear,
        withLogo,
        chasisNumber,
        insuranceCompany,
        insuranceExpirationDate,
      } = data;

      if (action == "edit") {
        const res = await vehiclesService.editVehicle({
          color,
          vehicleType: vehicleType,
          seats: parseInt(seats),
          images: uploadedImages,
          plateNumber,
          model,
          brand,
          fabricationYear: parseInt(fabricationYear),
          withLogo: withLogo == "false" || withLogo == false ? false : true,
          chasisNumber,
          insuranceCompany,
          insuranceExpirationDate,
          id: currentVehicle.id,
        });
      } else {
        const res = await vehiclesService.registerVehicle({
          color,
          vehicleType: vehicleType,
          seats: parseInt(seats),
          images: uploadedImages,
          plateNumber,
          model,
          brand,
          fabricationYear: parseInt(fabricationYear),
          withLogo: withLogo == "false" ? false : true,
          chasisNumber,
          insuranceCompany,
          insuranceExpirationDate,
        });
      }

      setLoading(false);
      onRegister();
    } catch (error) {
      setLoading(false);
      console.log(error);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  function handleFiles(event) {
    const files = event.target.files;
    const urls = [];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const imageUrl = event.target.result;
        urls.push(imageUrl);
        if (files.length == urls.length) {
          setVehicleImages([...vehicleImages, ...urls]);
        }
      };

      reader.readAsDataURL(file);
    });
  }

  const [vehicleTypes, setVehicleTypes] = useState([]);

  const getPricingData = async () => {
    try {
      const res = await vehiclesService.getPricingData();
      setVehicleTypes(res?.data?.pricingData);
    } catch (error) {}
  };

  useEffect(() => {
    getPricingData();
  }, []);

  return (
    <Modal isCentered={true} size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {action === "register" ? "Register Vehicle" : "Edit Vehicle"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={"100%"} onSubmit={add} as={"form"}>
            <input
              maxLength={5}
              onChange={handleFiles}
              style={{ display: "none" }}
              type="file"
              ref={imageInput}
              multiple
              accept="image/*"
            />

            <Flex mb={"10px"} columnGap={5}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle type
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  value={data?.vehicleType}
                  mb={"2px"}
                  placeholder="Select vehicle type"
                  onChange={(e) => {
                    setData({ ...data, vehicleType: e.target.value });
                  }}
                >
                  {vehicleTypes?.map((type) => {
                    return (
                      <option value={type?.id}>{type?.vehicleType}</option>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Brand
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data?.brand}
                  onChange={(e) => {
                    setData({ ...data, brand: e.target.value?.toUpperCase() });
                  }}
                  type={"text"}
                  textTransform={"uppercase"}
                  placeholder="Ex: Toyota, Ford ,Honda ,Tesla, etc"
                />
              </FormControl>
            </Flex>

            <Flex columnGap={5} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Model
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data?.model}
                  textTransform={"uppercase"}
                  onChange={(e) => {
                    setData({ ...data, model: e.target.value?.toUpperCase() });
                  }}
                  type={"text"}
                  placeholder="Ex: Corolla, Camry, RAV4, Prius, etc"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  With logo?
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  value={data?.withLogo}
                  mb={"2px"}
                  placeholder="With logo?"
                  onChange={(e) => {
                    setData({
                      ...data,
                      withLogo:
                        e.target.value == "false"
                          ? false
                          : e.target.value == "true"
                          ? true
                          : null,
                    });
                  }}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
              </FormControl>
            </Flex>

            <Flex mb={"10px"} columnGap={5}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Plate number
                </FormLabel>
                <Input
                  py={"22px"}
                  textTransform={"uppercase"}
                  maxLength={8}
                  value={data?.plateNumber}
                  onChange={(e) => {
                    setData({
                      ...data,
                      plateNumber: e.target.value?.toUpperCase(),
                    });
                  }}
                  type={"text"}
                  placeholder="Enter plate number"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Chasis number
                </FormLabel>
                <Input
                  py={"22px"}
                  textTransform={"uppercase"}
                  maxLength={17}
                  value={data?.chasisNumber}
                  onChange={(e) => {
                    setData({
                      ...data,
                      chasisNumber: e.target.value?.toUpperCase(),
                    });
                  }}
                  type={"text"}
                  placeholder="Enter chasis number"
                />
              </FormControl>
            </Flex>

            <Flex mb={"13px"} columnGap={5}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Insurance Company
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data?.insuranceCompany}
                  onChange={(e) => {
                    setData({ ...data, insuranceCompany: e.target.value });
                  }}
                  type={"text"}
                  placeholder="Insurance Company"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Insurance Expiration Date
                </FormLabel>
                <Input
                  py={"22px"}
                  type={"date"}
                  value={
                    data?.insuranceExpirationDate
                      ? new Date(data?.insuranceExpirationDate)
                          ?.toISOString()
                          ?.split("T")[0]
                      : ""
                  }
                  onChange={(e) => {
                    setData({
                      ...data,
                      insuranceExpirationDate: e.target.value,
                    });
                  }}
                  placeholder="Insurance Expiration Date"
                />
              </FormControl>
            </Flex>

            <FormControl isRequired mb={"13px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Upload vehicle image
              </FormLabel>
              <Box
                py={"30px"}
                px={"30px"}
                onClick={() => {
                  imageInput.current.click();
                }}
                borderRadius={"12px"}
                borderStyle={"dashed"}
                borderWidth={3}
                borderColor={"lightgray"}
                bg={"#F8FAFB"}
                mt={"5px"}
                textAlign={"center"}
                cursor={"pointer"}
              >
                <Button colorScheme={"primary"}>Browse</Button>
              </Box>
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                columnGap={"10px"}
                rowGap={"10px"}
                pt={"10px"}
              >
                {vehicleImages?.map((img, index) => {
                  return (
                    <Center
                      pos={"relative"}
                      width={"150px"}
                      height={"150px"}
                      key={"img" + index}
                      boxShadow={"xs"}
                    >
                      <Image
                        borderRadius={"4px"}
                        objectFit={"contain"}
                        maxHeight={"100%"}
                        maxWidth={"100%"}
                        objectPosition={"top"}
                        src={img}
                      />
                      <button
                        onClick={() => {
                          setVehicleImages(
                            vehicleImages.filter((imgs) => imgs != img)
                          );
                        }}
                        type="button"
                        className="closeBtn"
                      >
                        <IoMdClose size={20} />
                      </button>
                    </Center>
                  );
                })}
              </Box>
            </FormControl>

            <Flex columnGap={5}>
              <FormControl mb={"10px"} isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Vehicle Fabrication Year
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  value={data?.fabricationYear}
                  mb={"2px"}
                  placeholder="Vehicle Fabrication Year"
                  onChange={(e) => {
                    setData({
                      ...data,
                      fabricationYear: Number(e.target.value),
                    });
                  }}
                >
                  {[...Array(currentYear - startYear + 1)].map((_, index) => {
                    const year = startYear + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl isRequired mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Color of the vehicle
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  value={data?.color}
                  mb={"2px"}
                  placeholder="Color of the vehicle"
                  onChange={(e) => {
                    setData({ ...data, color: e.target.value });
                  }}
                >
                  <option value="Red">Red</option>
                  <option value="Blue">Blue</option>
                  <option value="Green">Green</option>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Silver">Silver</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              <FormControl isRequired mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Number Of Seats
                </FormLabel>
                <Select
                  fontWeight="medium"
                  fontSize="14px"
                  h={"45px"}
                  mb={"2px"}
                  value={data?.seats}
                  placeholder="Number of Seats"
                  onChange={(e) => {
                    setData({ ...data, seats: Number(e.target.value) });
                  }}
                >
                  {[...Array(7)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>

            <ModalFooter justifyContent={"space-between"} px={0}>
              {action == "edit" ? (
                <Button
                  colorScheme={currentVehicle?.user ? "danger" : "success"}
                  type={"button"}
                  onClick={() => {
                    if (onChangeDriver && !currentVehicle?.user) {
                      onChangeDriver();
                    }
                    if(currentVehicle?.user){
                      onCloseShift()
                    }
                  }}
                >
                  {currentVehicle?.user ? "Close Shift" : "Assign Driver"}
                </Button>
              ) : (
                <Box></Box>
              )}

              <Flex columnGap={3}>
                <Button type={"button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  loadingText={"Saving..."}
                  isLoading={loading}
                  type={"submit"}
                  colorScheme="primary"
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
