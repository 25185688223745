import {
  Box,
  Center,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import "./auth.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/white-logo.png";
import { FiLogIn } from "react-icons/fi";
import { useEffect, useState } from "react";
import { authService } from "../../services/auth";

const Login = () => {
  // const { loginAdmin, loading } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [data, setData] = useState({
    email: undefined,
    password: undefined,
  });

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/trips?page=1&tab=1&status=ALL");
    }
  }, []);

  const login = async (e) => {
    try {
      e.preventDefault();
      if (data.email.trim().length > 0 && data.password.trim().length > 0) {
        setLoading(true);
        const res = await authService.loginAdmin(data);
        localStorage.setItem("access_token", res?.data.token);
        toast({
          position: "top",
          title: "Logged in successfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        navigate("/trips?page=1&tab=1&status=ALL");
        setLoading(false);
      } else {
        toast("Email and username are required");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        position: "top",
        title: "Error",
        status: "error",
        description: error?.response?.data?.message || error?.message,
        isClosable: true,
        duration: 2000,
      });
    }
  };

  return (
    <Box
      backgroundColor={"rgb(28 36 52)"}
      minH={"100vh"}
      py={"100px"}
      w={"100%"}
    >
      <Center w={"100%"} mb={"20px"}>
        <Image src={logo} w={"250px"} />
      </Center>
      <Center py={"20px"} w={"100%"}>
        <Center
          boxShadow="base"
          bg={"white"}
          className="auth-forms"
          w={"30%"}
          px={"30px"}
          pt={"30px"}
          pb={"20px"}
          rounded={"md"}
        >
          <Box w={"100%"} onSubmit={login} as={"form"}>
            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Email address
              </FormLabel>
              <Input
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                type={"email"}
                placeholder="Email address"
              />
            </FormControl>

            <FormControl isRequired mb={"30px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Password
              </FormLabel>
              <Input
                py={"22px"}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
                type={"password"}
                placeholder="Password"
              />
            </FormControl>

            <Box>
              <Button
                type={"submit"}
                fontWeight={"bold"}
                fontSize={"16px"}
                backgroundColor={"#3e98d4"}
                isLoading={loading ? true : false}
                loadingText={"Signing in..."}
                w={"100%"}
                mb={"10px"}
                _hover={{ backgroundColor: "#3e98d4" }}
                color={"#fff"}
                py={"25px"}
                display={"flex"}
                borderRadius={"100px"}
                columnGap={"5px"}
              >
                <FiLogIn size={20}></FiLogIn>
                Sign In
              </Button>

              <Center>
                <Button background={"transparent"} color={"#3e98d4"}>
                  <Link to={"forgot-password"}>Forgot Password?</Link>
                </Button>
              </Center>
            </Box>
          </Box>
        </Center>
      </Center>
    </Box>
  );
};

export default Login;
