import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Select,
  FormLabel,
  FormControl,
  Flex,
  Textarea,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import AvailableDriversModal from "../../components/common/availableDriversModal";
import { TbDotsVertical } from "react-icons/tb";
import TripInfoModal from "../../components/common/tripInfoModal";
import RequestInfoModal from "../../components/common/more-request-info-modal";
import WarningModal from "../../components/common/warning-modal";
import SuccessModal from "../../components/common/success-modal";
import CurrencyInput from "react-currency-input-field";
import { adminService } from "../../services/admins";
import moment from "moment";
import { driversService } from "../../services/drivers";
import { IoSearch } from "react-icons/io5";
import SearchDrivers from "./searchDriver";
import { FiPlus } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

const SpecialRequestsTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  refetch,
  status,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const [currency, setCurrency] = useState("USD");
  const [reason, setReason] = useState("");
  const [price, setPrice] = useState(0);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [description, setDescription] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const complete = async () => {
    try {
      if (!selectedDrivers || selectedDrivers?.length == 0 || !currency) {
        toast({
          position: "top",
          title: "Validation error",
          status: "error",
          description: "Select drivers for this special request to complete",
          isClosable: true,
          duration: 2000,
        });
        return;
      }
      const currencies = selectedDrivers?.map((d) => {
        return d?.price;
      });
      if (currencies.some((item) => item == null || item < 1)) {
        toast({
          position: "top",
          title: "Validation error",
          status: "error",
          description: "Prices are required",
          isClosable: true,
          duration: 2000,
        });
        return;
      }
      setIsCompleting(true);
      const res = await adminService.completeRequest({
        currency,
        requestId: currentRequest?.id,
        selectedDrivers: selectedDrivers,
        description: description,
      });
      toast({
        position: "top",
        title: "Success",
        status: "success",
        description: res?.data?.message,
        isClosable: true,
        duration: 2000,
      });
      refetch();
      setIsOpen2(false);
      setIsOpen(false);
      setIsCompleting(false);
      setTimeout(() => {
        setSelectedDrivers([]);
        setSearchKey("");
      }, 500);
    } catch (error) {
      toast({
        position: "top",
        title: "Failed!",
        status: "error",
        description: error?.response?.data?.message || error?.message,
        isClosable: true,
        duration: 2000,
      });
      setIsCompleting(false);
    }
  };

  const reject = async () => {
    try {
      if (reason?.trim()?.length == 0) {
        toast({
          position: "top",
          title: "Validation error",
          status: "error",
          description: "Rejection reason is required",
          isClosable: true,
          duration: 2000,
        });
        return;
      }
      setIsRejecting(true);
      const res = await adminService.rejectRequest({
        reason: reason,
        requestId: currentRequest?.id,
      });
      toast({
        position: "top",
        title: "Success",
        status: "success",
        description: res?.data?.message,
        isClosable: true,
        duration: 2000,
      });
      refetch();
      setIsOpen3(false);
      setIsOpen(false);
      setIsRejecting(false);
    } catch (error) {
      toast({
        position: "top",
        title: "Failed!",
        status: "error",
        description: error?.response?.data?.message || error?.message,
        isClosable: true,
        duration: 2000,
      });
      setIsRejecting(false);
    }
  };

  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searched, setSearched] = useState(false);

  const searchDrivers = async (searchKey) => {
    try {
      setSearched(true);
      setLoadingDrivers(true);
      const res = await driversService.getDrivers(10, 1, null, searchKey);
      setDrivers(res?.data?.drivers);
      setLoadingDrivers(false);
    } catch (error) {
      setLoadingDrivers(false);
    }
  };

  useEffect(() => {
    console.log("selectedDrivers", selectedDrivers);
  }, [selectedDrivers]);

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th whiteSpace={"nowrap"} textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Special requests
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No special requests available, once they are
                                  available they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr
                        onClick={() => {
                          setCurrentRequest(data);
                          setIsOpen(true);
                          setDescription(data?.description);
                        }}
                        cursor={"pointer"}
                      >
                        <Td>
                          <Box>{data?.user?.fullName}</Box>
                          <Box>{data?.user?.phoneNumber}</Box>
                        </Td>
                        <Td>
                          {data?.amount
                            ? (data?.currency == "USD" ? "$" : data?.currency) +
                              ""
                            : ""}
                          {data?.amount?.toLocaleString() || " - "}
                        </Td>
                        <Td>
                          <Box
                            textAlign={"center"}
                            color={"#fff"}
                            bg={
                              data?.status == "PENDING"
                                ? "#F7A01F"
                                : data?.status == "COMPLETED"
                                ? "#0ED27D"
                                : "#F43A4D"
                            }
                            py={2.5}
                            px={2}
                            borderRadius={8}
                          >
                            {data?.status == "REJECTED"
                              ? "CANCELLED"
                              : data?.status}
                          </Box>
                        </Td>
                        <Td className="force-wrap" isTruncated={true}>
                          {data?.description}
                        </Td>
                        <Td>
                          {moment(new Date(data?.createdAt)).format(
                            "DD/MM/YY hh:mm A"
                          )}
                        </Td>
                        <Td onClick={(e) => e.stopPropagation()}>
                          <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setDescription(data?.description);
                                  setCurrentRequest(data);
                                  setIsOpen(true);
                                }}
                              >
                                View more
                              </MenuItem>
                              {data?.status == "PENDING" && (
                                <MenuItem
                                  onClick={() => {
                                    setCurrentRequest(data);
                                    setIsOpen2(true);
                                    setDescription(data?.description);
                                  }}
                                >
                                  Mark as completed
                                </MenuItem>
                              )}
                              {data?.status == "PENDING" && (
                                <MenuItem
                                  onClick={() => {
                                    setCurrentRequest(data);
                                    setIsOpen3(true);
                                  }}
                                >
                                  Cancel
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(`/trips?page=${page}&tab=${tab}&status=${status}`);
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {isOpen && (
        <RequestInfoModal
          setDescription={setDescription}
          description={description}
          onSave={() => {
            refetch();
            setIsOpen(false);
          }}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          request={currentRequest}
          onComplete={() => {
            setIsOpen2(true);
          }}
          onReject={() => {
            setIsOpen3(true);
          }}
        />
      )}

      {isOpen3 && (
        <WarningModal
          isOpen={isOpen3}
          onClose={() => {
            setIsOpen3(false);
          }}
          child={
            <Box mt={"10px"}>
              <FormControl isRequired mb={"10px"}>
                <FormLabel>Reason</FormLabel>
                <Textarea
                  placeholder="Enter the reason for rejecting this request"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </FormControl>
            </Box>
          }
          isLoading={isRejecting}
          title={"Cancel special request"}
          description={"Are you sure you want to cancel this request?"}
          btnText={"Cancel request"}
          onConfirm={() => {
            reject();
          }}
        />
      )}

      {isOpen4 && (
        <SearchDrivers
          isOpen={isOpen4}
          onClose={() => setIsOpen4(false)}
          onConfirm={() => {
            setIsOpen4(false);
          }}
          child={
            <Box>
              <InputGroup mb={"15px"}>
                <InputLeftElement pointerEvents="none">
                  <IoSearch color="gray.300" />
                </InputLeftElement>
                <Input
                  autoFocus={true}
                  placeholder="Search drivers to assign trip"
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                    searchDrivers(e.target.value);
                  }}
                />
              </InputGroup>

              {searched && (
                <VStack spacing={4} align="stretch">
                  {loadingDrivers ? (
                    <>
                      <Skeleton
                        startColor={"#F4F4F4"}
                        borderRadius={"md"}
                        endColor={"#dddddd"}
                        height={"60px"}
                      />
                      <Skeleton
                        startColor={"#F4F4F4"}
                        borderRadius={"md"}
                        endColor={"#dddddd"}
                        height={"60px"}
                      />
                      <Skeleton
                        startColor={"#F4F4F4"}
                        borderRadius={"md"}
                        endColor={"#dddddd"}
                        height={"60px"}
                      />
                    </>
                  ) : drivers?.length > 0 ? (
                    drivers.map((driver) => (
                      <Box
                        key={driver.id}
                        borderWidth="1px"
                        borderRadius="md"
                        p={4}
                        borderColor={
                          selectedDrivers?.find(
                            (d) => d?.driverId == driver?.id
                          )
                            ? "green"
                            : ""
                        }
                      >
                        <HStack justify="space-between">
                          <Box>
                            <Text fontWeight="bold">{driver?.fullName}</Text>
                            <Text fontSize="base" color="gray.500">
                              {driver.phoneNumber}
                            </Text>
                            <Text fontSize="base" color="gray.500">
                              {driver.vehicles?.length > 0
                                ? driver.vehicles[0]?.vehicle?.vehicleType
                                : "No vehicle assigned"}
                            </Text>
                            {driver?.vehicles?.length > 0 && (
                              <Flex columnGap={"5px"}>
                                <Text
                                  title="Plate number"
                                  fontSize="base"
                                  color="gray.500"
                                >
                                  {driver.vehicles[0]?.plateNumber}
                                </Text>{" "}
                                |
                                <Text
                                  title="Vehicle Model"
                                  fontSize="base"
                                  color="gray.500"
                                >
                                  {driver.vehicles[0]?.model}
                                </Text>{" "}
                                |
                                <Text
                                  title="Vehicle Brand"
                                  fontSize="base"
                                  color="gray.500"
                                >
                                  {driver.vehicles[0]?.brand}
                                </Text>
                              </Flex>
                            )}
                          </Box>
                          <Button
                            px={
                              selectedDrivers?.find(
                                (d) => d?.driverId == driver?.id
                              )
                                ? "5px"
                                : "20px"
                            }
                            onClick={() => {
                              if (
                                !selectedDrivers?.find(
                                  (d) => d?.driverId == driver?.id
                                )
                              ) {
                                setSelectedDrivers([
                                  ...selectedDrivers,
                                  {
                                    driverId: driver?.id,
                                    name: driver?.fullName,
                                    price: 0,
                                    plateNumber:
                                      driver.vehicles[0]?.plateNumber,
                                    vehicleType:
                                      driver.vehicles[0]?.vehicle?.vehicleType,
                                    vehicle: {
                                      vehicleTypeId:
                                        driver.vehicles[0]?.vehicle?.id,
                                      vehicleId: driver.vehicles[0]?.id,
                                    },
                                  },
                                ]);
                              } else {
                                setSelectedDrivers(
                                  selectedDrivers?.filter(
                                    (d) => d?.driverId != driver?.id
                                  )
                                );
                              }
                            }}
                            className={
                              selectedDrivers?.find(
                                (d) => d?.driverId == driver?.id
                              )
                                ? "selectBtn"
                                : ""
                            }
                            colorScheme={
                              !selectedDrivers?.find(
                                (d) => d?.driverId == driver?.id
                              )
                                ? "standard"
                                : "white"
                            }
                            size="sm"
                          >
                            {selectedDrivers?.find(
                              (d) => d?.driverId == driver?.id
                            ) && (
                              <IoMdCheckmarkCircleOutline
                                size={22}
                                color="green"
                              />
                            )}
                            {selectedDrivers?.find(
                              (d) => d?.driverId == driver?.id
                            )
                              ? "Selected"
                              : "Select"}
                          </Button>
                        </HStack>
                      </Box>
                    ))
                  ) : (
                    <Text>Results not found</Text>
                  )}
                </VStack>
              )}
            </Box>
          }
        />
      )}

      {isOpen2 && (
        <SuccessModal
          isOpen={isOpen2}
          onClose={() => {
            setSelectedDrivers([]);
            setSearchKey("");
            setIsOpen2(false);
          }}
          isLoading={isCompleting}
          title={"Assign Drivers to this trip"}
          btnText={"Complete"}
          child={
            <Box>
              <Button
                mb={"15px"}
                variant="ghost"
                onClick={() => {
                  setIsOpen4(true);
                }}
                colorScheme="blue"
              >
                <FiPlus size={21} />
                Add Drivers
              </Button>

              {selectedDrivers?.map((driver) => {
                return (
                  <Box
                    borderWidth="1px"
                    borderRadius="md"
                    p={4}
                    borderColor={"green"}
                    mb={"10px"}
                  >
                    <FormControl position={"relative"} mb={"10px"}>
                      <button
                        onClick={() => {
                          setSelectedDrivers(
                            selectedDrivers?.filter(
                              (d) => d?.driverId != driver?.driverId
                            )
                          );
                        }}
                        className="xclosebtn"
                      >
                        <IoCloseSharp size={24} color="white" />
                      </button>
                      <FormLabel fontWeight={"700"}>Driver/Vehicle</FormLabel>
                      <Flex columnGap={"5px"} flexWrap={"wrap"}>
                        <Text color="gray.500">{driver?.name}</Text>|
                        <Text fontWeight={"500"} color="gray.500">
                          {driver?.vehicleType}
                        </Text>
                      </Flex>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontWeight={"700"}>Amount</FormLabel>
                      <Flex>
                        <CurrencyInput
                          className="currency-input-field"
                          id="input-example"
                          name="input-name"
                          placeholder="Enter amount paid"
                          defaultValue={data?.pricePerHourDay}
                          value={data?.pricePerHourDay}
                          decimalsLimit={2}
                          maxLength={20}
                          onValueChange={(value, name) => {
                            setSelectedDrivers(
                              selectedDrivers?.map((_driver) => {
                                if (_driver?.driverId == driver?.driverId) {
                                  return {
                                    ..._driver,
                                    price: value ? parseInt(value) : "",
                                  };
                                } else {
                                  return _driver;
                                }
                              })
                            );
                          }}
                        />
                        <Select
                          borderLeftRadius={0}
                          h={"45px"}
                          bg="neutral.300"
                          w={"150px"}
                          onChange={(e) => {
                            setCurrency(e.target.value);
                          }}
                          value={currency}
                        >
                          <option value={""} disabled>
                            Select currency
                          </option>
                          {currencyCodes?.map((code) => {
                            return (
                              <option
                                selected={currency == code ? true : false}
                                value={code}
                              >
                                {code}
                              </option>
                            );
                          })}
                        </Select>
                      </Flex>
                    </FormControl>
                  </Box>
                );
              })}
            </Box>
          }
          onConfirm={() => {
            complete();
          }}
        />
      )}
    </Box>
  );
};

export default SpecialRequestsTable;
