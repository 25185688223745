import axiosInstance from "../utils/axios";

export const vehiclesService = {
  getPricingData: async (getAllData = null) => {
    try {
      let url = `/vehicles/pricingData`;
      if (getAllData) {
        url += `?getAllData=${true}`;
      }
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  getAllVehicles: async (itemsPerPage, currentPage, status, searchKey) => {
    try {
      let url = `/vehicles?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}`;
      if (searchKey) {
        url += `&searchKey=${searchKey}`;
      }
      return axiosInstance.get(url);
    } catch (error) {
      throw error;
    }
  },
  closeShift: async (data) => {
    try {
      return axiosInstance.put(`/vehicles/closeShift`, data);
    } catch (error) {
      throw error;
    }
  },
  editPricingData: async (data) => {
    try {
      return axiosInstance.put(`/vehicles/editPricingData`, data);
    } catch (error) {
      throw error;
    }
  },
  addPricingData: async (data) => {
    try {
      return axiosInstance.post(`/vehicles/addVehicleType`, data);
    } catch (error) {
      throw error;
    }
  },
  editVehicle: async (data) => {
    try {
      return axiosInstance.put(`/vehicles/edit/${data?.id}`, data);
    } catch (error) {
      throw error;
    }
  },
  registerVehicle: async (data) => {
    try {
      return axiosInstance.post("/vehicles/", data);
    } catch (error) {
      throw error;
    }
  },
  assignDriver: async (data) => {
    try {
      return axiosInstance.put("/vehicles/assignDriver/", data);
    } catch (error) {
      throw error;
    }
  },
};
