import { Box, Flex, Link, Heading, Text, Image } from "@chakra-ui/react";
import playStore from "../../assets/images/googleplay-fr.png";
import appStore from "../../assets/images/appstore-fr.png";
import playStoreEn from "../../assets/images/googleplay.png";
import appStoreEn from "../../assets/images/appstore.png";
import phoneScreenShot from "../../assets/images/phone.png";
import { useState } from "react";
import AppNavBar from "./navbar";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";

export default function HomePage() {
  const { t } = useTranslation();
  return (
    <Box bgAttachment="fixed" className="homepage">
      <AppNavBar />
      <Flex
        h="calc(100vh - 65px)"
        px={"150px"}
        className="homepagecontent"
        columnGap={"200px"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          w={"50%"}
          position={"relative"}
          className="part1"
        >
          <Box w={"100%"}>
            <Heading
              color={"#ffff"}
              fontWeight={"700"}
              fontSize={55}
              className="homeheader"
            >
              {t("welcomeMsg")}
            </Heading>
            <Heading
              mb={"20px"}
              color={"#ffff"}
              fontWeight={"700"}
              fontSize={55}
              className="homeheader"
            >
              {t("welcomeMsg2")}
            </Heading>
            <Text className="homedesc" mb={"60px"} color={"#fff"} fontSize={20}>
              {t("appDescription")}
            </Text>

            <Flex mb={"50px"}>
              <Link href={"#"} _hover={{ color: "#0070f3" }}>
                <Image
                  h={"50px"}
                  src={i18n.language == "en" ? playStoreEn : playStore}
                />
              </Link>
              <Link
                href={"#"}
                color={"#fff"}
                _hover={{ color: "#0070f3" }}
                ml={"15px"}
                fontSize={20}
                borderColor={"gray"}
                borderWidth={i18n.language == "en" ? 0 : 0}
                borderRadius={6}
              >
                <Image
                  h={"50px"}
                  src={i18n.language == "en" ? appStoreEn : appStore}
                />
              </Link>
            </Flex>
          </Box>

          <Text
            pos={"absolute"}
            bottom={"40px"}
            color={"#AFAFAF"}
            className="copyright"
            fontSize={14}
          >
            © {new Date().getFullYear()} {t("copyright")}
          </Text>
        </Box>

        <Box
          className="part2"
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          w={"50%"}
          h={"100%"}
        >
          <Image className="image-app" h={"85%"} src={phoneScreenShot} />
        </Box>
      </Flex>
    </Box>
  );
}
